/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import { WEBIEW_MESSAGE } from 'constants/webview';
import { STARTUP_ACCESS_STATUS } from 'constants/startup';
import { formatErrors } from 'utils/functions';
import { literalTemplate } from 'utils/language';
import { sendMessageToWebview } from 'utils/sendMessageToWebview';
import { ROUTE_STARTUP_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SendRequestIcon } from 'assets/svg/send-request.svg';
import { ReactComponent as TwoFactorAuthenticationIcon } from 'assets/svg/two-factor-authentication.svg';
import { joinToStartup, requestStartupAccess } from '../modules/actions';
import SharePopup from './SharePopup';
import './styles.scss';

const RequestAccess = (props) => {
  const {
    literals,
    literalsCommon,
    startup,
    embed,
    render,
    setRender,
    mode,
  } = props;

  const STARTUP_SEARCHER = 'startupSearcher';
  const FRONT_PAGE = 'frontPage';
  const MESSAGE_OWNER_EXIST = 'messageOwnerExist';
  const MESSAGE_REQUIRE_VALIDATION = 'requireValidation';

  const [loading, setLoading] = useState(true);
  const [sharePopup, setSharePopup] = useState(false);
  const [requestStatus, setRequestStatus] = useState({ admin: false, owner: false });
  const [dns, setDns] = useState(false);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const notifyParent = async (data) => {
    setLoading(true);
    const cleanData = { ...data };
    delete cleanData.captable;
    delete cleanData.relation;
    // eslint-disable-next-line no-underscore-dangle
    delete cleanData._searchScore;

    sendMessageToWebview(WEBIEW_MESSAGE.NEW_STARTUP, cleanData);
  };

  const checkStartup = async () => {
    setLoading(true);
    const isExternal = !!(!startup.id && startup.externalId);
    const auxId = isExternal ? startup.externalId : startup.id;
    const response = await joinToStartup(auxId, isExternal);

    if ([STARTUP_ACCESS_STATUS.USER_ALREADY_EXIST, STARTUP_ACCESS_STATUS.ACCESS_GRANTED].includes(response.status)) {
      if (!embed) {
        navigate(ROUTE_STARTUP_PATH.setHome(response.startup.id));
      } else {
        notifyParent(response.startup);
      }
    } else if (response.status === STARTUP_ACCESS_STATUS.ACCESS_EXTERNAL_GRANTED) {
      if (!embed) {
        navigate(ROUTE_STARTUP_PATH.setOnboardingPhantom(response.startup.id));
      } else {
        notifyParent(response.startup);
      }
    } else if (render === MESSAGE_REQUIRE_VALIDATION) {
      dialog({
        text: literals.couldNotVerify, type: 'error',
      });
    } else {
      switch (response.status) {
        case STARTUP_ACCESS_STATUS.EXIST_OWNER:
        case STARTUP_ACCESS_STATUS.ACCESS_REQUEST_PENDING:
        case STARTUP_ACCESS_STATUS.ACCESS_REQUEST_REJECTED:
          setRequestStatus({ ...requestStatus, owner: response?.status });
          setRender(MESSAGE_OWNER_EXIST);
          break;
        case STARTUP_ACCESS_STATUS.REQUIRE_VALIDATION:
          setDns(response?.dns);
          setRender(MESSAGE_REQUIRE_VALIDATION);
          setRequestStatus({ ...requestStatus, admin: response?.adminRequestStatus });
          break;
        default:
          setRender(FRONT_PAGE);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    checkStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const joinWithoutAccess = async () => {
    setLoading(true);
    try {
      const response = await joinToStartup(startup.id || startup.externalId, !startup.id, true);
      setLoading(false);

      if (!embed) {
        navigate(ROUTE_STARTUP_PATH.setHome(response.startup.id));
      } else {
        notifyParent(response.startup);
      }
    } catch (err) {
      setLoading(false);
      dialog({
        text: literalsCommon.genericError,
        type: 'error',
      });
    }
  };

  const requestAccess = async (type, external = false) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        await requestStartupAccess(startup?.id || startup.externalId, type, external)
          .then(async () => {
            joinWithoutAccess();
            await dialog({
              text: literals.requestSentSuccessfully, type: 'success',
            });
            setRequestStatus({ ...requestStatus, [type]: 'access_request_sent' });
          })
          .catch(err => dialog({
            text: literals[formatErrors(err).error[0].code], type: 'error',
          }));
      },
    });
  };

  const renderOwnerExist = () => {
    let message = literals.messageOwnerExist;
    if (requestStatus.owner === 'access_request_pending') {
      message = literals.accessRequestPending;
    } else if (requestStatus.owner === 'access_request_sent') {
      message = literals.accessRequestSent;
    } else if (requestStatus.owner === 'access_request_rejected') {
      message = literals.accessRequestRejected;
    }

    return (
      <>
        <h3 className='step-title'>{literals.requestAccess}</h3>
        <p className='step-message'>{literalTemplate(message, { STARTUP_NAME: startup.name })}</p>
        <SendRequestIcon className='step-svg' />
        <div className='buttons justify-content-between mt-4 mb-4'>
          <Button
            color='secondary'
            text={mode === 'onboarding' ? literals.previous : literalsCommon.close}
            onClick={() => setRender(STARTUP_SEARCHER)}
          />
          { requestStatus.owner === 'exist_owner' && (
            <Button
              text={literals.request}
              onClick={() => requestAccess('owner')}
            />
          )}
        </div>
        { mode === 'onboarding' && (
          <>
            <hr />
            <div className='d-flex align-items-center justify-content-center'>
              <Button
                color='transparent'
                text={literals.accessWithoutPermission}
                onClick={joinWithoutAccess}
                loading={loading}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const renderRequireValidation = () => {
    const message = startup.url
      ? (startup?.id
        ? literals.messageValidationRequired
        : literals.messageValidationRequiredExternal
      )
      : literals.requestAccessToAdmin;

    return (
      <>
        <h3 className='step-title'>{literals.verifyProperty}</h3>
        <p className='step-message'>
          {literalTemplate(message, {
            STARTUP_NAME: startup.name,
          })}
          <br />
          <br />
          { startup.url && dns && literalTemplate(literals.messageValidationDNS, {
            DOMAIN: dns.name,
            DNS_VALUE: dns.value,
          })}
        </p>
        <TwoFactorAuthenticationIcon className='step-svg' />
        <div className='buttons justify-content-between mt-4 mb-4'>
          <Button
            color='secondary'
            text={mode === 'onboarding' ? literals.previous : literalsCommon.close}
            onClick={() => setRender(STARTUP_SEARCHER)}
          />
          <div className='buttons'>
            <Button
              color='secondary'
              text={literals.shareWithFounder}
              onClick={() => setSharePopup(true)}
              loading={loading}
            />
            { startup.url && (
              <Button
                color='secondary'
                text={literals.retry}
                onClick={checkStartup}
                loading={loading}
              />
            )}
            {
              startup?.id && (
                <Button
                  text={requestStatus.admin ? literals.requested : literals.request}
                  onClick={() => requestAccess('admin', !startup.id && !!startup.externalId)}
                  loading={loading}
                  disabled={!!requestStatus.admin}
                />
              )
            }
          </div>
        </div>
        { mode === 'onboarding' && (
          <>
            <hr />
            <div className='d-flex align-items-center justify-content-center'>
              <Button
                color='transparent'
                text={literals.accessWithoutPermission}
                onClick={joinWithoutAccess}
                loading={loading}
              />
            </div>
          </>
        )}
      </>
    );
  };

  if (loading) {
    return <Loading mode='panel' hide={false} />;
  }

  return (
    <>
      {render === MESSAGE_OWNER_EXIST && renderOwnerExist()}
      {render === MESSAGE_REQUIRE_VALIDATION && renderRequireValidation()}
      {
        sharePopup && (
          <SharePopup
            literals={literals}
            literalsCommon={literalsCommon}
            startup={startup}
            onClose={() => setSharePopup(false)}
          />
        )
      }
    </>
  );
};

RequestAccess.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  render: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  setRender: PropTypes.func.isRequired,
  embed: PropTypes.bool,
  mode: PropTypes.string,
};

RequestAccess.defaultProps = {
  embed: false,
  mode: 'onboarding',
};

export default RequestAccess;
