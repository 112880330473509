/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import Avatar from 'components/Avatar';
import InternalForm from 'components/InternalForm';
import IOFileLoading from 'components/IOFileLoading/components';
import useDialog from 'components/Dialog/components/useDialog';
import { formatDate, getFullName } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { ROLE_MENTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import { Eye, UserPlus, Briefcase, Pencil, Trash, Download, Users as People } from 'lucide-react';
import { fetchUsers, putMentor, getStartups, deleteUser, downloadExcelUsers, impersonateUser } from '../modules/actions';
import FormUsers from './FormUsers';
import FormEditUser from './FormEditUser';
import './styles.scss';


const Users = ({ literals, literalsCommon, literalsAccount, user, showLoading }) => {
  const [popupInfo, setPopupInfo] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewUserStartup, setViewUserStartup] = useState(null);
  const [editProfile, setEditProfile] = useState(null);
  const [idEditProfile, setIdEditProfile] = useState(null);
  const tableRefreshFlag = useCounter(0);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const getUsers = (page, size, search, filters, sort) => {
    return fetchUsers(page, size, { search, filters, sort });
  };

  useEffect(() => {
    getUsers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editMentor = (row) => {
    setEditProfile(row.mentor);
    setIdEditProfile(row.id);
  };

  const handleDeleteUser = async (row) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: '¿Borrar?',
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteUser(row.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const handleEditMentor = async (id, data) => {
    await putMentor(id, data);
    tableRefreshFlag.increase();
    setEditProfile(null);
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelUsers('Users.xlsx');
    setDownloadingExcel(false);
  };

  const handleImpersonateUser = async (id) => {
    await showLoading(true);
    await impersonateUser(id);
    await showLoading(false);
    navigate(ROUTE_PATH.PREHOME, { replace: true });
  };

  const formatName = (row) => {
    return (
      <div>
        {row.name}
        <p className='d-block text-secondary font-weight-light'>
          <em>{row.description.length > 75 ? `${row.description.substr(0, 72)}...` : row.description}</em>
        </p>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div className='popUp-info'>
        <div className='popUp-info-header'>
          <div className='popUp-info-header-avatar'>
            <Avatar
              title={getFullName(popupInfo)}
              src={popupInfo.avatar?.path}
              size={100}
            />
          </div>
          <div className='popUp-info-header-name'>
            <h3>Usuario</h3>
            <p>{getFullName(popupInfo)}</p>
          </div>
        </div>
        <div className='popUp-info-body'>
          <h6 className='fw-b'>{literals.date}</h6>
          <p>{formatDate(popupInfo.create_at, { time: true })}</p>
          <h6 className='fw-b'>{literals.email}</h6>
          <p>{popupInfo.email}</p>
          <h6 className='fw-b'>{literals.lastAccess}</h6>
          <p>{formatDate(popupInfo.login_at, { time: true })}</p>
          <h6 className='fw-b'>{literals.lastModification}</h6>
          <p>{formatDate(popupInfo.modified_at, { time: true })}</p>
          <h6 className='fw-b'>{literals.role}</h6>
          <p>{popupInfo.role[0]}</p>
          { Object.keys(popupInfo.extra ?? []).length && (
            <>
              <h6 className='fw-b'>{literals.extra}</h6>
              <p>{JSON.stringify(popupInfo.extra)}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  const filters = [
    {
      id: 'role',
      type: 'select',
      title: literals.role,
      options: [
        { id: 'admin', name: literalsAccount.roles.admin },
        { id: 'user', name: literalsAccount.roles.user },
        { id: 'mentor', name: literalsAccount.roles.mentor },
      ],
    },
    {
      id: 'login_at',
      type: 'dateRange',
      title: literals.lastAccess,
    },
  ];

  const columns = [
    { field: 'avatar', type: 'avatar', title: (_, row) => `${row.firstname} ${row.lastname}`, width: 45, label: literals.avatar },
    {
      field: 'firstname',
      grow: 1,
      label: literals.fullname,
      preRender: (f, row) => `${row.firstname} ${row.lastname}`,
    },
    { field: 'email', label: literals.email, grow: 2 },
    { field: 'role', width: 100, label: literals.role, preRender: role => (role ? role.map(r => literalsAccount.roles[r]).join(',') : '') },
    {
      field: 'login_at',
      width: 140,
      label: literals.lastAccess,
      preRender: loginAt => (loginAt ? formatDate(loginAt, { time: true }) : '-'),
      sortable: true,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literals.view,
          onClick: startup => setPopupInfo(startup),
        },
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: row => setPopupEdit(row),
        },
        {
          icon: Briefcase,
          text: 'Startup',
          onClick: startup => setViewUserStartup(startup),
        },
        {
          icon: Pencil,
          text: 'Mentor',
          onClick: row => editMentor(row),
          hidden: (id, row) => !row.role.includes(ROLE_MENTOR),
        },
        {
          icon: People,
          text: 'Impersonar',
          onClick: row => handleImpersonateUser(row.id),
        },
        {
          icon: Trash,
          text: literals.delete,
          onClick: row => handleDeleteUser(row),
          disabled: id => id === user.id,
        },
      ],
    },
  ];

  const columnsStartup = [
    {
      field: 'logo', type: 'avatar', title: (_, row) => row.name, width: 45, label: 'Logo',
    },
    {
      field: 'name', label: 'Name', grow: 5, preRender: (n, row) => formatName(row),
    },
    {
      field: 'role', label: 'Role', width: 45, preRender: () => viewUserStartup.role[0],
    },
  ];

  const formButtons = [
    {
      type: 'submit',
      onClick: data => handleEditMentor(idEditProfile, data),
      text: 'Enviar',
    },
    { onClick: () => setEditProfile(null), text: 'Cancelar', color: 'secondary' },
  ];

  const getStartupsAux = (page, size, search) => {
    return getStartups(viewUserStartup.id, page, size, search);
  };

  return (
    <Tool>
      <Panel>
        <PanelHeader title={literals.users} />
        <PanelBody>
          <Table
            filters={filters}
            columns={columns}
            pageSize={10}
            actions={[
              { icon: UserPlus, text: literals.invite, onClick: () => setEditMode(true) },
              { icon: Download, text: 'Excel', onClick: handleDownloadExcel },
            ]}
            fetch={getUsers}
            forceFetch={tableRefreshFlag.value}
          />
          {editMode && (
            <FormUsers
              literals={literals}
              literalsAccount={literalsAccount}
              tableRefreshFlag={tableRefreshFlag}
              onClose={() => setEditMode(false)}
            />
          )}
        </PanelBody>
      </Panel>
      {popupInfo && (
        <Popup title='Info' onClose={() => setPopupInfo(null)}>
          {renderInfo()}
        </Popup>
      )}
      {popupEdit && (
        <FormEditUser
          literals={literals}
          literalsAccount={literalsAccount}
          user={popupEdit}
          tableRefreshFlag={tableRefreshFlag}
          onClose={() => setPopupEdit(null)}
        />
      )}
      {viewUserStartup && (
        <Popup onClose={() => setViewUserStartup(null)}>
          <Table pageSize={10} filters={filters} columns={columnsStartup} fetch={getStartupsAux} />
        </Popup>
      )}
      {editProfile && (
        <Popup onClose={() => setEditProfile(null)}>
          <InternalForm answers={editProfile} type='mentor' buttons={formButtons} />
        </Popup>
      )}
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'Users.xlsx' }} /> }
    </Tool>
  );
};

Users.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsAccount: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  showLoading: PropTypes.func.isRequired,
};

export default Users;
